import React, { useState } from 'react'
import { useInputValue } from '../hooks'
import { client } from '../../Client'
import { toastrNotification } from '../../../helpers/Toastr'

export default function LectionForm({ lection, course, updateLectionTitle }) {
  const [title, setTitle] = useInputValue(lection.title)

  function saveTitle() {
    client.updateCourseLection(course.id, lection.course_section_id, lection.id, {title: title})
    .then(response => {
      if (response.id) {
        updateLectionTitle(title)
        toastrNotification({ type: 'success', title: window.t('updated') })
      } else {
        toastrNotification({ type: 'error', title:  window.t('not_updated') })
      }
    })
  }

  return (
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card border rounded-3">
          <div className="card-body">
            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor="lectionTitle">{ window.t("course.lection_title") }</label>
                <input type="text" value={title} onChange={setTitle} onBlur={saveTitle} className="form-control" id="lectionTitle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
