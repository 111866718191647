import React from 'react'
import { client } from './../Client'
import Uppy from '@uppy/core'
import Russian from '@uppy/locales/lib/ru_RU'
import Tus from '@uppy/tus'
import { DashboardModal } from '@uppy/react'
import { toastrNotification } from '../../helpers/Toastr'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

class FileUploader extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      modalOpen: false,
      uploadedFiles: 0,
      isReady: false
    }

    const locale = this.props.currentLocale == "ru" ? Russian : 'en_EN'

    this.uppy = new Uppy({
      restrictions: { maxNumberOfFiles: 10 },
      autoProceed: true,
      locale: locale
    }).use(Tus, { endpoint: window.upload_endpoint, chunkSize: 5*1024*1024 })

    this.uppy.on('upload-success', (file, response) => {
      let fileData = {title: file.name,
                      file_orig_name: file.name,
                      file_url: response.uploadURL,
                      file_size: file.size,
                      file_type: file.meta.type}
      this.createFile(fileData)
      const newUsedSpace = this.state.usedSpace + file.size
      this.setState(Object.assign({}, this.state, { usedSpace: newUsedSpace }))
    })

    this.uppy.on('file-removed', (file, reason) => {
      if (file.uploadURL) {
        client.deleteFile(file.uploadURL)
      }
    })

    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentWillUnmount () {
    this.uppy.close()
  }

  handleOpen () {
    this.setState({ modalOpen: true })
  }

  handleClose () {
    this.setState({
      modalOpen: false
    }, () => {
      if (this.state.uploadedFiles > 0) {
        Turbo.visit(window.location)
      }
    })
  }

  createFile(fileData) {
    client.createFile(fileData)
    .then((response) => {
      this.setState({ uploadedFiles: this.state.uploadedFiles + 1 })
    })
  }

  render () {
    return (
      <>
        <button className="btn btn-sm btn-white btn-block" onClick={this.handleOpen} disabled={!this.state.isReady}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
          { window.t("add_files") }
        </button>
        <DashboardModal
          uppy={this.uppy}
          closeModalOnClickOutside
          open={this.state.modalOpen}
          onRequestClose={this.handleClose}
          target={document.body}
          proudlyDisplayPoweredByUppy={false}
          showLinkToFileUploadResult={false}
          showRemoveButtonAfterComplete={false}
          doneButtonHandler={this.handleClose}
        />
      </>
    );
  }
}

export default FileUploader